/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import { updateTotalDiscount } from './discountActions';
// @ts-ignore
import { cartHelper, productHelper } from '../../helpers';
import { updateProductsWithCart } from './productActions';
import { getStorage } from '../../helpers/pricebook';

import ProductAPI from '../../api/ProductAPI';
import { CLEAR_SHIPPING_DATA } from '../types/shippingActions.type';
/* import OrderAPI from '../../api/OrderAPI';
import order from '../../helpers/order'; */

export const ADD_ORDER_TO_CART = 'ADD_ORDER_TO_CART';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_DIRECT_PRODUCT_TO_CART = 'ADD_DIRECT_PRODUCT_TO_CART';
export const ADD_PRODUCT_TO_CART_QTY = 'ADD_PRODUCT_TO_CART_QTY';
export const ALERT_PRODUCTS = 'ALERT_PRODUCTS';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART_STATE = 'UPDATE_CART_STATE';
export const DECREASE_PRODUCT_IN_CART = 'DECREASE_PRODUCT_IN_CART';
export const DELIVER_STATUS_CHANGE = 'DELIVER_STATUS_CHANGE';
export const INCREASE_PRODUCT_IN_CART = 'INCREASE_PRODUCT_IN_CART';
export const REMOVE_PRODUCT_IN_CART = 'REMOVE_PRODUCT_IN_CART';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_DESC = 'SET_DESC';
export const SET_SINGLE_DISCOUNT = 'SET_SINGLE_DISCOUNT';
export const UPDATE_PRODUCT_PRICE_IN_CART = 'UPDATE_PRODUCT_PRICE_IN_CART';
export const UPDATE_PRODUCT_QTY_IN_CART = 'UPDATE_PRODUCT_QTY_IN_CART';
export const UPDATE_SERVICE_PRICE_BY_WEIGHT_CART = 'UPDATE_SERVICE_PRICE_BY_WEIGHT_CART';
export const VERIFY_PAYPAL_SERVICE = 'VERIFY_PAYPAL_SERVICE';
export const VERIFY_SECURITY_SERVICE = 'VERIFY_SECURITY_SERVICE';
export const VERIFY_SHIPPING_SERVICE = 'VERIFY_SHIPPING_SERVICE';
export const UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const RESET_PROMOTIONS = 'RESET_PROMOTIONS';
export const ACTIVE_PROMOTIONS = 'ACTIVE_PROMOTIONS';
export const DESACTIVE_PROMOTIONS = 'DESACTIVE_PROMOTIONS';
export const ADD_SHIPPING_SERVICE = 'ADD_SHIPPING_SERVICE';
export const OPEN_CART_DETAIL = 'OPEN_CART_DETAIL';
export const SET_CAR_DETAIL_ACTIVE_TAB = 'SET_CAR_DETAIL_ACTIVE_TAB';
export const SET_PRODUCT_SERIE = 'SET_PRODUCT_SERIE';

// Guide
export const ADD_SELECTED_GUIDE = 'ADD_SELECTED_GUIDE';
export const CLEAR_SELECTED_GUIDE = 'CLEAR_SELECTED_GUIDE';

// Packing
export const ADD_SELECTED_PACKING = 'ADD_SELECTED_PACKING';
export const CLEAR_SELECTED_PACKING = 'CLEAR_SELECTED_PACKING';

/* Discount */
export const SET_DISCOUNT_SHOW = 'SET_DISCOUNT_SHOW';
export const SET_DISCOUNT_HIDE = 'SET_DISCOUNT_HIDE';

/* Update products stock */
export const ADD_PRODUCTS_STOCK = 'ADD_PRODUCTS_STOCK';
export const CHANGE_SIN_STOCK = 'CHANGE_SIN_STOCK';

// Update totals
export const UPDATE_TOTALS = 'UPDATE_TOTALS';

// Update order currency
export const UPDATE_ORDER_CURRENCY = 'UPDATE_ORDER_CURRENCY';

const isCEN = () => (dispatch, getState) => {
  const { authUser } = getState();
  const { user } = authUser;
  if (user.cen !== undefined) {
    dispatch(verifyPaypalService());
    dispatch(verifyShippingService());
    dispatch(verifySecurityService());
  }
  return null;
};


export function togglePromotions() {
  return {
    type: SET_PROMOTIONS,
  };
}

export function setActivePromotions() {
  return {
    type: ACTIVE_PROMOTIONS,
  };
}

export function desactivePromotions() {
  return {
    type: DESACTIVE_PROMOTIONS,
  };
}

export function resetPromotions() {
  return {
    type: RESET_PROMOTIONS,
  };
}

export function toggleOpenDetail(value) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CART_DETAIL,
      open: value,
    });
  };
}

export function setProductSerie(product) {
  return async (dispatch, getState) => {
    const { cart } = getState();
    const { products } = cart;
    const productIndex = products.findIndex(p => p.crmid === product.crmid);
    const updateProductSeries = product.series && products[productIndex].series && products[productIndex].series.length !== product.series.length;

    await dispatch({
      type: SET_PRODUCT_SERIE,
      product,
    });
    if (updateProductSeries) {
      await dispatch(verifyProductStock('setProductSerie'));
      await dispatch(updatePromotions());
    }
  };
}

export function openDetailTab(tab) {
  return (dispatch) => {
    dispatch({
      type: SET_CAR_DETAIL_ACTIVE_TAB,
      tab,
    });
  };
}

export function evaluateStock() {
  return async (dispatch, getState) => {
    try {
      const ProductApi = new ProductAPI();
      dispatch(loading('order'));
      toast.info('Actualizando existencias');
      const { cart, filters, discount } = getState();
      const { discountTaxes } = filters;
      const { cartProductsById, orderSelected } = cart;
      const requests = Object.entries(cartProductsById).map((product) => {
        let productid = '';
        if (product[1].productid) {
          productid = product[1].productid.indexOf('x') !== -1
            ? product[1].productid.split('x').pop()
            : product[1].productid;
        }
        const crmid = product[1].crmid ? product[1].crmid : productid;
        return ProductApi.getWithData(crmid, { data: product[1] });
      });
      await Promise.all(requests)
        .then(async (stockRes) => {
          const response = stockRes.map(x => ({
            ...x.data,
            qtyinstock: x.result.qtyinstock,
          }));
          let jsonById = {};
          response.forEach((result) => {
            const inStock = {
              sumQty: result.quantity,
              inStock: parseInt(result.qtyinstock),
              status: Boolean(result.quantity <= parseInt(result.qtyinstock)),
            };
            const product = { ...result, inStock };
            jsonById = { ...jsonById, [result.row]: { ...product } };
            dispatch(clearCart());
            dispatch({
              type: UPDATE_CART_STATE,
              cartProductsById: jsonById,
              orderSelected,
              discountTaxes,
              discount,
            });
          });
        })
        .catch((evaluateStockErr) => {
          toast.warn(
            `Al momento de varificar stock del carrito en el sistema, encontramos un error: ${evaluateStockErr}`,
          );
        });
    } catch (err) {
      toast.warn(
        `Al momento de actualizar stock en el carrito, encontramos un error: ${err}`,
      );
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function addDirectProductToCart(parentId, crmid, quantity = null, prod = null) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { authUser, product, discount } = getState();

      const {
        user: {
          config: { pos_cart_concepts },
          shipping_id,
        },
      } = authUser;
      const type = ADD_DIRECT_PRODUCT_TO_CART;
      const { productsById } = product;
      const { filters } = getState();
      const { discountTaxes } = filters;
      const inStock = { status: true, sumQty: quantity || 1, inStock: 10000 };
      await dispatch({
        type,
        crmid,
        parentId,
        quantity,
        productsById,
        pos_cart_concepts,
        inStock,
        discountTaxes,
        discount,
        prod,
      });

      await dispatch(updateProductsWithCart());
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      // Move outside
      await dispatch(verifyProductStock('AddDirectProductToCart'));
      await dispatch(updatePromotions());

      if (crmid !== shipping_id) {
        await dispatch(deleteShippingProduct());
      }
    } catch (err) {
      toast.warn(
        `Al momento de añadir un producto al carrito, encontramos un error: ${err}`,
      );
    } finally {
      await dispatch(loaded('order'));
    }
  };
}
export function addProductToCart(parentId, crmid, quantity = null, withOutPriceList = false) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { authUser, product, discount } = getState();

      const {
        user: {
          config: { pos_cart_concepts, pos_sininv },
          shipping_id,
        },
      } = authUser;
      let type = '';
      if (pos_cart_concepts) {
        type = ADD_PRODUCT_TO_CART;
      } else {
        type = ADD_PRODUCT_TO_CART_QTY;
      }
      const { productsById } = product;
      const { cart, filters } = getState();
      const { discountTaxes } = filters;
      let inStock = {};

      const ProductApi = new ProductAPI();
      const result = await ProductApi.get(crmid, withOutPriceList);
      if (!pos_sininv) {
        inStock = await productHelper.haveStockBD(
          result,
          crmid,
          quantity,
          cart,
        );
      } else inStock = { status: true, sumQty: quantity || 1, inStock: 10000 };

      await dispatch({
        type,
        crmid,
        parentId,
        quantity,
        productsById,
        pos_cart_concepts,
        inStock,
        discountTaxes,
        discount,
        productfromAction: result.result,
      });

      await dispatch(updateProductsWithCart());
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      // Move outside
      await dispatch(verifyProductStock('AddProductToCart'));
      await dispatch(updatePromotions());

      if (crmid !== shipping_id) {
        await dispatch(deleteShippingProduct());
      }
    } catch (err) {
      toast.warn(
        `Al momento de añadir un producto al carrito, encontramos un error: ${err}`,
      );
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function addOrderToCart(actualOrder) {
  return async (dispatch, getState) => {
    if (actualOrder) {
      const {
        product, discount, order: { enableEditOrder }, filters: { discountTaxes },
      } = getState();
      const productsById = { ...product.productsById };
      /* const response = orderAPI.getOrder(actualOrder.crmid);
      const { success, result } = response;
      const orderResult = success ? result : actualOrder; */
      const orderResult = actualOrder;

      const discountTaxesStatus = enableEditOrder ? discountTaxes : (orderResult?.sin_impuestos === '1' && parseFloat(orderResult?.sumadeimpuestos) === 0);
      dispatch(clearCart());
      dispatch({
        type: ADD_ORDER_TO_CART,
        order: orderResult,
        productsById,
        discountTaxes: discountTaxesStatus,
        discount,
      });

      await dispatch(updateTotalDiscount());
    }
  };
}

export function uploadDiscountAmount(row) {
  return async (dispatch, getState) => {
    const {
      cart: { cartProductsById },
    } = getState();
    const percentage = cartProductsById[row].discount_percent;
    await dispatch(setSingleDiscount(row, percentage));
  };
}

export function increaseProductInCart(row, count = 1) {
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { cart, authUser, discount } = getState();
      const { crmid } = cart.cartProductsById[row];
      const productid = cart.cartProductsById[row].productid
        && cart.cartProductsById[row].productid.indexOf('x') !== -1
        ? cart.cartProductsById[row].productid.split('x').pop()
        : cart.cartProductsById[row].productid;

      const setCrmid = crmid || productid;
      const {
        user: {
          config: { pos_sininv, pos_promo_cart },
        },
      } = authUser;
      let inStock = {};
      
      if (!pos_sininv) {
        const ProductApi = new ProductAPI();
        const result = await ProductApi.get(setCrmid);
        inStock = await productHelper.haveStockBD(result, setCrmid, parseFloat(count), cart);
      } else inStock = { status: true, sumQty: 1, inStock: 10000 };
      await dispatch({
        type: INCREASE_PRODUCT_IN_CART,
        row,
        inStock,
        discount,
        count,
      });
      await dispatch(updateProductsWithCart());
      await dispatch(uploadDiscountAmount(row));
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      // Move outside
      dispatch(verifyProductStock('IncreaseProductInCart'));
      dispatch(updatePromotions());
      if (cart.cartProductsById[row].index !== 'gastos_de_envio_por_cotizar') {
        await dispatch(deleteShippingProduct());
      }
    } catch (err) {
      toast.warn(
        `Al momento de incrementar cantidad de un producto, encontramos un error: ${err}`,
      );
    }
  };
}

export function decreaseProductInCart(row) {
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { cart, authUser, discount } = getState();
      const { crmid } = cart.cartProductsById[row];
      const productid = cart.cartProductsById[row].productid
        && cart.cartProductsById[row].productid.indexOf('x') !== -1
        ? cart.cartProductsById[row].productid.split('x').pop()
        : cart.cartProductsById[row].productid;

      const setCrmid = crmid || productid;
      const {
        user: {
          config: { pos_sininv, pos_promo_cart },
        },
      } = authUser;
      let inStock = {};
      if (!pos_sininv) {
        const ProductApi = new ProductAPI();
        const result = await ProductApi.get(setCrmid);
        inStock = await productHelper.haveStockBD(result, setCrmid, -1, cart);
      } else inStock = { status: true, sumQty: 1, inStock: 10000 };
      await dispatch({
        type: DECREASE_PRODUCT_IN_CART,
        row,
        inStock,
        discount,
      });
      await dispatch(updateProductsWithCart());
      await dispatch(uploadDiscountAmount(row));
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      // Move outside
      dispatch(verifyProductStock('DecreaseProductInCart'));
      await dispatch(updatePromotions());
      if (cart.cartProductsById[row].index !== 'gastos_de_envio_por_cotizar') {
        await dispatch(deleteShippingProduct());
      }
    } catch (err) {
      toast.warn(
        `Al momento de reducir cantidad de un producto, encontramos un error: ${err}`,
      );
    }
  };
}

export function updatePriceByPricebook(row, pricebook = null) {
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      await dispatch(loading('order'));
      const { cart } = getState();
      // const { crmid } = cart.cartProductsById[row];
      const product = cart.cartProductsById[row];
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;
      const crmid = product.crmid || productid;
      const ProductApi = new ProductAPI();
      const response = await ProductApi.getWithPricebook(crmid, pricebook);
      if (response.success) {
        const {
          result: { unit_price },
        } = response;
        const price = unit_price.toFixed(2);
        await dispatch(updateProductPriceInCart(row, price));
        await dispatch(uploadDiscountAmount(row));
        await dispatch(updateTotalDiscount());
      } else {
        const { message } = response;
        toast.warn(message.warning || message.error);
      }
    } catch (err) {
      toast.warn(
        `Al momento de cambiar el precio de un producto, encontramos un error: ${err}`,
      );
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function updateProductQuantityInCart(row, qty) {
  return async (dispatch, getState) => {
    const { discount, cart } = getState();
    await dispatch({
      type: UPDATE_PRODUCT_QTY_IN_CART,
      row,
      qty,
      discount,
    });
    dispatch(updateProductsWithCart());
    dispatch(updateTotalDiscount());
    dispatch(updateServicePriceByWeightCart());
    dispatch(isCEN());
    // Move outside
    dispatch(verifyProductStock('UpdateProductQuantityInCart'));
    dispatch(updatePromotions());
    /* if (cart.cartProductsById[row]?.index !== 'gastos_de_envio_por_cotizar') {
      dispatch(deleteShippingProduct('UpdateProductQuantityInCart'));
    } */
  };
}

export function updateProductPriceInCart(row, price, changeFromCart = false) {
  return async (dispatch, getState) => {
    const { discount } = getState();
    dispatch({
      type: UPDATE_PRODUCT_PRICE_IN_CART,
      row,
      price,
      discount,
      changeFromCart,
    });
    await dispatch(uploadDiscountAmount(row));
    await dispatch(updateTotalDiscount());
  };
}

export function updateServicePriceByWeightCart() {
  return (dispatch, getState) => {
    const { authUser, discount } = getState();
    const { user } = authUser;
    const isValid = Object.prototype.hasOwnProperty.call(user, 'cen');
    if (isValid) {
      const { cen } = user;
      dispatch({
        type: UPDATE_SERVICE_PRICE_BY_WEIGHT_CART,
        cen,
        discount,
      });
      dispatch(updateTotalDiscount());
    }
  };
}

export function removeProductInCart(row) {
  return (dispatch, getState) => {
    const { discount, cart, authUser } = getState();
    dispatch({
      type: REMOVE_PRODUCT_IN_CART,
      row,
      discount,
    });
    dispatch(updateProductsWithCart());
    dispatch(updateTotalDiscount());
    dispatch(updateServicePriceByWeightCart());
    /* if (cart.activePromotions) {
      dispatch(updatePromotions());
    } */
    dispatch(updatePromotions());
  };
}

export function verifyPaypalService() {
  return (dispatch, getState) => {
    const {
      cart: { cartProductsByArray, totalWithOutServices },
    } = getState();
    const array = cartProductsByArray.filter(x => x.crmid === '55235');
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const payPalPrice = Math.round(0.025 * totalWithOutServices * 100) / 100;
      dispatch({ type: VERIFY_PAYPAL_SERVICE });
      dispatch(updateProductPriceInCart(shippingRow, payPalPrice));
    }
  };
}

export function verifySecurityService() {
  return (dispatch, getState) => {
    const {
      cart: { cartProductsByArray, totalWithOutServices },
    } = getState();
    const array = cartProductsByArray.filter(x => x.crmid === '152666');
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const securityServices = Math.round(0.05 * totalWithOutServices * 100) / 100;
      dispatch({ type: VERIFY_SECURITY_SERVICE });
      dispatch(updateProductPriceInCart(shippingRow, securityServices));
    }
  };
}

export function verifyShippingService() {
  return async (dispatch, getState) => {
    const {
      cart: { cartProductsByArray, totalWeight },
    } = getState();
    const {
      authUser: {
        user: { cen },
      },
    } = getState();
    const { id_envio_aereo, id_envio_terrestre } = cen;
    const array = cartProductsByArray.filter(
      x => x.crmid === String(id_envio_aereo)
        || x.crmid === String(id_envio_terrestre),
    );
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const shippingCrmid = Number.parseInt(array[0].crmid);
      let elements = [];
      if (cen.id_envio_terrestre === shippingCrmid) {
        elements = cen.envio_terrestre;
      } else {
        elements = cen.envio_aereo;
      }
      const shippingOption = await cartHelper.getShippingData(
        totalWeight,
        elements,
      );
      dispatch(updateProductPriceInCart(shippingRow, shippingOption[0].x));
    }
  };
}

export function alertProducts(noHayDe) {
  return (dispatch) => {
    const productsWithOutStock = Object.entries(noHayDe).map(x => ({
      id: x[0],
      qty: x[1],
    }));
    dispatch({
      type: ALERT_PRODUCTS,
      productsWithOutStock,
    });
  };
}

export function adjustStock() {
  return (dispatch, getState) => {
    try {
      const { cart } = getState();
      cart.alertProducts.forEach((x) => {
        if (x.qty <= 0) {
          dispatch(removeProductInCart(x.id));
        }
        dispatch(updateProductQuantityInCart(x.id, x.qty));
      });
    } catch (err) {
      /* Error */
      console.log('adjustStock err: ', err);
    }
  };
}

export function setDesc(description) {
  return async (dispatch) => {
    await dispatch({
      type: SET_DESC,
      description,
    });
  };
}

export function updatePromotions() {
  return async (dispatch, getState) => {
    const ProductApi = new ProductAPI();
    const {
      cart, authUser, filters: { discountTaxes }, discount, order: { enableEditOrder },
    } = getState();
    const priceBookSelected = getStorage();
    const currentStore = authUser.isAuth ? authUser.user.config.store : {};
    const data = {
      origen: 'TPV',
      treebesalmid: currentStore.crmid,
      pricebookid: priceBookSelected.crmid || 0,
      products: [],
    };

    /**
     * Verifica promociones
     */
    /* if (activePromotions) { */
    cart.products.map((product) => {
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;

      data.products.push({
        productid: product.crmid || productid,
        quantity: product.quantity,
        unit_price: product.unit_price,
      });
    });
    if (data.products.length) {
      const promotions = await ProductApi.getPromotions(data);
      const {
        success,
        result,
      } = promotions;
      if (success && result) {
        await dispatch({
          type: UPDATE_PROMOTIONS,
          TreebesPromociones: result.TreebesPromociones,
          products: result.products,
          discountTaxes,
          discount,
          enableEditOrder,
        });
      } else {
        toast.error('Error al obtener promociones');
      }
    }
    /* } */
  };
}

export function setComment(product) {
  return async (dispatch, getState) => {
    const { discount } = getState();
    await dispatch({
      type: SET_COMMENT,
      product,
      discount,
    });
  };
}

export function setSingleDiscount(row, percentage) {
  return async (dispatch, getState) => {
    const {
      cart: { cartProductsById, subTotal }, discount,
    } = getState();
    const product = cartProductsById[row];
    if (!product) {
      return;
    }
    const { unit_price, quantity, totalPrice } = product;
    const priceByQty = (unit_price || totalPrice) * quantity;
    const amount = priceByQty * (percentage / 100);
    await dispatch({
      type: SET_SINGLE_DISCOUNT,
      row,
      amount,
      percentage,
      discount,
    });
    if (discount.haveDiscount) {
      const total = subTotal + discount.total - amount;
      await dispatch(updateTotalDiscount(total));
    }
  };
}

export function setDiscount(percentage, show = false) {
  return async (dispatch, getState) => {
    const {
      cart: { products },
    } = getState();
    if (show) {
      products.forEach(async (product) => {
        const { unit_price, row } = product;
        const discount = unit_price * (percentage / 100);
        const newPrice = unit_price - discount;
        await dispatch(updateProductPriceInCart(row, newPrice));
      });
    } else {
      products.forEach(async (product) => {
        const { row } = product;
        await dispatch(setSingleDiscount(row, percentage));
      });
    }
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export const setPromotions = () => async (dispatch) => {
  dispatch(togglePromotions());
};

export const setProductsById = product => async (dispatch, getState) => {
  const { discount, shipping } = getState();
  if (shipping?.shippingList.length > 0) {
    for (const p of shipping?.shippingList) {
      product.unit_price += p.unit_price;
    }
  }
  await dispatch({
    type: ADD_SHIPPING_SERVICE,
    product,
    discount,
  });
};

export const deleteShippingProduct = () => async (dispatch, getState) => {
  const { cart, authUser } = getState();
  const {
    user: {
      shipping_id,
    },
  } = authUser;
  const index = Object.values(cart.cartProductsById).findIndex(prod => prod.crmid === shipping_id);
  const rowIndex = index + 1;
  if (index !== -1) {
    await dispatch({
      type: REMOVE_PRODUCT_IN_CART,
      row: rowIndex,
    });
    await dispatch({
      type: CLEAR_SHIPPING_DATA,
    });
    toast.info('La cantidad de productos cambió, debes volver a cotizar tu envío');
  }
};

export const verifyProductStock = (message = '') => async (dispatch, getState) => {
  const { cart: { orderSelected, products }, authUser: { user } } = getState();
  console.log('message-from: ', message);
  const { crmid } = orderSelected;
  const { config: { store } } = user;
  const ProductApi = new ProductAPI();
  const productsIds = [];

  if (products && products.length) {
    products.map((prod) => {
      // eslint-disable-next-line no-nested-ternary
      const productid = prod.crmid ? prod.crmid : (prod.productid.indexOf('x') !== -1
        ? prod.productid.split('x').pop()
        : prod.productid);

      productsIds.push(productid);
    });
  }

  const data = {
    crmid: crmid || 0,
    treebesalmid: store.crmid,
    products_ids: productsIds,
  };

  if (productsIds.length) {
    const response = await ProductApi.getProductsStock(data);
    const { success, result } = response;
    if (success) {
      await dispatch({
        type: ADD_PRODUCTS_STOCK,
        stock: result,
      });
    }
  }
};

export const changeSinStock = sinStock => async (dispatch) => {
  await dispatch({
    type: CHANGE_SIN_STOCK,
    sinStock,
  });
};

// Guide
export function addSelectedGuide(guide) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_GUIDE,
      guide,
    });
  };
}


export function clearSelectedGuide() {
  return {
    type: CLEAR_SELECTED_GUIDE,
  };
}

// Packing
export function addSelectedPacking(packing) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_PACKING,
      packing,
    });
  };
}


export function clearSelectedPacking() {
  return {
    type: CLEAR_SELECTED_PACKING,
  };
}


// Update totals
export function updateTotals() {
  return (dispatch, getState) => {
    const {
      cart, filters, discount, order: { enableEditOrder },
    } = getState();
    const { discountTaxes } = filters;
    const { cartProductsById, orderSelected } = cart;
    // eslint-disable-next-line no-nested-ternary
    const existOrder = Object.keys(orderSelected).length;
    // eslint-disable-next-line no-nested-ternary
    const discountTaxesStatus = !existOrder ? discountTaxes : (enableEditOrder ? discountTaxes : (orderSelected.order?.sin_impuestos === '1' && parseFloat(orderSelected?.order.sumadeimpuestos) === 0));
    dispatch({
      type: UPDATE_TOTALS,
      cartProductsById,
      discountTaxes: discountTaxesStatus,
      discount,
    });
  };
}


// Change cart currency
export function updateProductCartCurrency(selectedCurrency) {
  return (dispatch, getState) => {
    const {
      cart, authUser,
    } = getState();
    const { user: { usable_currencies: currencies } } = authUser;
    const {
      cartProductsById, products, orderSelected,
    } = cart;
    const { order } = orderSelected;

    // Get order currency
    const actualOrderCurrencyId = order.currency_id.split('x').pop();
    const actualOrderCurrency = currencies.find(c => c.id === actualOrderCurrencyId);

    // Change order currency id, replace last character for new currency selected
    orderSelected.order.currency_id = orderSelected.order.currency_id.replace(/.$/, `${selectedCurrency.id}`);

    /**
     * Calculate conversion rate
     * Ex: Selected currency conversion rate (USD) / actual order conversion rate (EUR)
     *     0.05 / 0.04 = 1.25
     *     if product price is 10 EUR => 10 * 1.25 = 12.5 USD
     */
    const currencyConversionRate = parseFloat(selectedCurrency.conversion_rate) / parseFloat(actualOrderCurrency.conversion_rate);

    // Update products array
    products.forEach((product) => {
      // eslint-disable-next-line operator-assignment
      const newPrice = product.unit_price * currencyConversionRate;
      product.unit_price = newPrice;
    });

    // Update products by id
    // eslint-disable-next-line guard-for-in
    for (const product1 in cartProductsById) {
      const newPrice = cartProductsById[product1].unit_price * currencyConversionRate;
      cartProductsById[product1].unit_price = newPrice;
    }


    dispatch({
      type: UPDATE_ORDER_CURRENCY,
      products,
      cartProductsById,
      cartProductsByArray: products,
      orderSelected,
    });
  };
}

// Update cart product price when client change
export function updateProductsInCartWhenChangePriceBook(priceBookId) {
  return (dispatch, getState) => {
    const {
      cart: { cartProductsByArray },
    } = getState();
    if (cartProductsByArray.length) {
      cartProductsByArray.map(product => dispatch(updatePricebook(product.row, priceBookId)));
    }
  };
}

const updatePricebook = (row, pricebook) => async (dispatch) => {
  await dispatch(updatePriceByPricebook(row, pricebook));
};
